export function extractErrorMessagesFromErrorOrResponse(e: any): string[] {
  if (
    typeof e === 'object' &&
    'response' in e &&
    typeof e.response === 'object' &&
    'data' in e.response &&
    e.response.data != null
  ) {
    // normal http error
    if (typeof e.response.data === 'string') {
      return [e.response.data]
    } else if (Array.isArray(e.response.data)) {
      return e.response.data
    } else if (typeof e.response.data === 'object' && typeof e.response.data.Message === 'string') {
      return [e.response.data.Message]
    } else {
      return [e.toString()]
    }
  } else if(typeof e === 'object' && e != null && 'toString' in e && typeof e.toString === 'function') {
    return [e.toString()]
  }
  return []
}
